import { useState } from 'react'
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack'
import logo from './logo.png'
import phone from './phone-line.svg'
import mail from './mail-send-line.svg'
import insta from './insta.svg'

import './App.css'

const options = {
  cMapUrl: 'cmaps/',
  cMapPacked: true
}

const menuDocs = [
  './menu1.pdf',
  './menu2.pdf',
  './menu3.pdf',
  './menu-vegan.pdf',
  // './menu4.pdf',
]

function App() {
  const [numPages, setNumPages] = useState(null)

  function onDocumentLoadSuccess({ numPages: nextNumPages }) {
    setNumPages(nextNumPages)
  }
  const impressumText = ` Kotti Dang Restaurant
  Straße: Kottbusser Damm 73
  PLZ und Ort: 10967 Berlin
  Land: Deutschland
  Name des Geschäftsführer: Khac Hoang Thai
  Telefon: +493028707467
  Umsatzsteuer-Identifikationsnummer gem. § 27a UStG:  DE58916342606`

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo noselect" alt="logo" />

        <p>Neue Öffnungszeiten ab 01.07.2023</p>
        <p>Mo.-So.: 11.30-23.00</p>
        {/*
        <p>Montag - Dienstag</p>
        <p>11:30 - 23:00</p>
        <p>Mittwoch Ruhetag</p>
        <p>Donnerstag - Sonntag</p>
        <p>11:30 - 23:00</p>
        */}
        <br/>
        <p>Kottbusser Damm 73</p>
        <p>10967 Berlin</p>
        <br/>

        <div className="topbar">
            <a href="https://www.instagram.com/kotti.dang/" target="_blank" rel="noopener noreferrer" className="insta-logo">
              <button>
                <img src={insta} alt="instagram"/>
              </button>
            </a>
            <a href="tel:+493028707467" className="">
              <button>
                <img src={phone} alt="tel"/>
              </button>
            </a>
        </div>

        <br/>

        <iframe className="gmaps" title="gmap" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9717.585518851309!2d13.4244701!3d52.4900641!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x12f7ffc53e9676cb!2sKotti+Dang!5e0!3m2!1sen!2sde!4v1537527425162" width="100%" height="100%" frameborder="0" allowfullscreen="" data-darkreader-inline-border-top="" data-darkreader-inline-border-right="" data-darkreader-inline-border-bottom="" data-darkreader-inline-border-left=""></iframe>

        <h3>Speisekarte</h3>

        { menuDocs.map(menuPdf => {
          return (
            <Document
              file={menuPdf}
              onLoadSuccess={onDocumentLoadSuccess}
              options={options}
            >
              { Array.from(
                new Array(numPages),
                (el, index) => (
                  <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                ),
              ) }
            </Document>
          )
          }) }

        <h4>Impressum</h4>
        <div className="impressum">
          <pre className="">{impressumText}</pre>
        </div>
        <br/>
      </header>
    </div>
  )
}

export default App
